<template>
    <form @submit.prevent="search" class="filter">
        <b-row>
            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{ $t("CHECKOUT_FROM") }}</label>
                <app-date-picker v-model="fromDate" :max-date="toDate"></app-date-picker>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{ $t("CHECKOUT_TO") }}</label>
                <app-date-picker v-model="toDate" :min-date="fromDate" :from-date="fromDate"></app-date-picker>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{ $t("OWNER_INVOICE") }}</label>
                <app-select :options="ownerInvoiceOptions" v-model="ownerInvoiceOptionValue"></app-select>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{ $t("TAGGED_THROUGH_PAYMENT_MESSAGE") }}</label>
                <app-select :options="paymentMessageOptions" v-model="paymentMessageOptionValue"></app-select>
            </b-col>

            <b-col xl="4" lg="4" md="6" class="mb-3">
                <app-button-submit class="filter-submit-wrapper" :inline="true"
                    :disabled="(loading || (!fromDateInput || !toDateInput || !invoiceInput))" :loading="loaderSubmit"
                    variant="primary" button_type="search">{{ $t("SEARCH") }}
                </app-button-submit>
                <app-button-reset @click="resetForm" :disabled="loading" :loading="loaderReset" :inline="true"
                    class="pl-2 ml-2"></app-button-reset>
                <app-button-submit class="pl-2 ml-2" :inline="true"
                    :disabled="(loading || (!fromDateInput || !toDateInput || !invoiceInput))" :loading="loaderExport"
                    variant="primary" @click="downloadXls()"> {{ $t("EXPORT") }}
                </app-button-submit>
            </b-col>
        </b-row>
    </form>
</template>

<script>
import AppDatePicker from "@/components/app/datetime/AppDatePicker.vue";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import AppButtonReset from "@/components/app/AppButton/AppButtonReset";
import AppButton from "@/components/app/AppButton/AppButton.vue";
import { toast } from "@/shared/plugins/toastr";
import { noTransactionReportDownloadXls } from "@/services/finance/no_transaction_report";
export default {
    name: "NoTransactionFilter",
    props: {
        loading: {
            type: Boolean,
            default: false
        }
    },
    components: {
        AppButton,
        AppDatePicker, AppButtonReset, AppButtonSubmit, AppSelect
    },
    data() {
        return {
            ownerInvoiceOptions: [
                { id: '0', name: this.$t('All') },
                { id: '1', name: this.$t('Owner Invoice Created') },
                { id: '2', name: this.$t('Owner Invoice not Created') }
            ],
            ownerInvoiceOptionValue: '0',
            paymentMessageOptions: [
                { id: '0', name: this.$t('No') },
                { id: '1', name: this.$t('Yes') }
            ],
            paymentMessageOptionValue: '0',
            dateObject: {
                'check_out_from_date': this.getDateTwoMonthsAgo(),
                'check_out_to_date': this.getTodayDate()
            },
            fromDate: this.getDateTwoMonthsAgo(),
            toDate: this.getTodayDate(),
            fromDateInput: true,
            toDateInput: true,
            loaderReset: false,
            loaderSubmit: false,
            loaderExport: false,
            reset: 0,
            invoiceInput: true,
        }
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
    },
    methods: {
        getDateTwoMonthsAgo() {
            const date = new Date();
            date.setMonth(date.getMonth() - 2);
            return date.toISOString().split('T')[0];
        },
        getTodayDate() {
            const date = new Date();
            return date.toISOString().split('T')[0];
        },
        search() {
            this.reset = 0
            this.$emit('search', { ...this.dateObject, ...{ owner_invoice_created: this.ownerInvoiceOptionValue, tagged_through_payment_message: this.paymentMessageOptionValue } })
        },
        resetForm() {
            this.reset = 1
            if (Object.keys(this.$route.query).length > 0) {
                this.$router.replace({ query: {} })
            }
            this.dateObject = {}
            this.ownerInvoiceOptionValue = '0',
            this.paymentMessageOptionValue = '0',
                this.fromDate = null
            this.toDate = null
            this.invoiceInput = true,
                this.fromDateInput = false,
                this.toDateInput = false
        },
        downloadXls() {
            this.loaderExport = true;
            let req = {
                ...this.dateObject,
                ...{
                    company: this.company.id,
                    owner_invoice_created: this.ownerInvoiceOptionValue,
                    tagged_through_payment_message: this.paymentMessageOptionValue
                }
            }
            noTransactionReportDownloadXls(req).then((response) => {
                let blob = new Blob([response], { type: 'application/xlsx' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `No Transaction Report - ${this.fromDate} - ${this.toDate}.xlsx`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            },
                (error) => {
                    toast({
                        'title': this.$t('Bad Request'),
                        'message': this.$t(error.response.data),
                        'type': 'error',
                        'timeout': 4000
                    })
                }).finally(() => {
                    this.loaderExport = false;
                })
        },
    },
    watch: {
        fromDate(value) {
            this.dateObject['check_out_from_date'] = value
            if (value) {
                this.fromDateInput = true;
            }
            else {
                this.fromDateInput = false;
            }
        },
        toDate(value) {
            this.dateObject['check_out_to_date'] = value
            if (value) {
                this.toDateInput = true;
            }
            else {
                this.toDateInput = false;
            }
        },
        ownerInvoiceOptionValue(value) {
            if (value) {
                this.invoiceInput = true
            }
        },
        loading: {
            handler(value) {
                if (this.reset) {
                    this.loaderReset = value;
                } else {
                    this.loaderSubmit = value;
                }
            }
        }
    }
}
</script>

<style scoped></style>
