import http from "@/services/http";
import {NO_TRANSACTION_REPORT_EXPORT_URL, NO_TRANSACTION_REPORT_FETCH_URL} from "@/services/endpoints";


export function getNoTransactionReport(params){
    return http.request({
        url: NO_TRANSACTION_REPORT_FETCH_URL,
        method: 'GET',
        params
    })
}

export function noTransactionReportDownloadXls(params){
    return http.request({
        url: NO_TRANSACTION_REPORT_EXPORT_URL,
        method: 'GET',
        responseType: 'blob',
        params
    })
}
