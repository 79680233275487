import {API_CONFIG} from '@/services/config'

export const API_URL = '/adminapi';

export const DOCUMENT_URL = '/adminapi/document';
export const AUTH_URL = '/oauth/token';
export const MAGIC_LINK_URL = '/adminapi/user/magic-link-token';
export const DISTRIBUTION_URL = '/adminapi/distribution';
export const DISTRIBUTION_VALIDATION_URL = DISTRIBUTION_URL + '-validation'
export const HEADLINE_URL = '/adminapi/distribution/headline';
export const DISTRIBUTION_LOOKUP_URL = '/adminapi/distribution/lookup';
export const DISTRIBUTION_LIST_URL = '/adminapi/distribution/list'
export const HEADLINE_MAPPING_URL = '/adminapi/distribution/headline/mapping'
export const DISTRIBUTION_DEFAULT_SETUP_URL = DISTRIBUTION_URL + '/default-setup'

// Locations
export const LOCATION_CITIES_URL = '/adminapi/location/cities';
export const LOCATION_COUNTRIES_URL = '/adminapi/location/countries';
export const LOCATION_MICROLOCATION_URL = '/adminapi/location/microlocations';
export const LOCATION_COUNTIES_URL = '/adminapi/location/counties';
export const LOCATION_REGIONS_URL = '/adminapi/location/regions';
export const LOCATION_CITY_MAPPING_URL = '/adminapi/location/cities-with-mapping';

// Property endpoints
export const PROPERTY_URL = "/adminapi/property"
export const PROPERTY_WITH_OWNER_URL = "/adminapi/property-with-owner"
export const PROPERTY_VALIDATION_URL = "/adminapi/property-validation"
export const PROPERTY_IMPORT_URL = "/adminapi/property/import"

export const ACC_MANAGER = "/adminapi/account-manager"
export const ACC_MANAGER_LIST = "/adminapi/account-manager-list"

export const COMPANY = "/adminapi/company"

// Inventory default
export const INVENTORY_DEFAULT_URL = "/adminapi/inventory-default"
export const INVENTORY_INFO_URL = "/adminapi/inventory-default/info"


// Reservation endpoints - old
export const ACCOMMODATION_RESERVATION_OLD = "/adminapi/accommodation-reservation"

// Reservation endpoints
export const ACCOMMODATION_RESERVATION = "/adminapi/accommodation-reservation-new"
export const ARPS = "/adminapi/accommodation-reservation-process-setup"
export const CONTACT = "/adminapi/contact"


export function PROPERTY_POI_URL(propertyId) {
    return `/adminapi/property/${propertyId}/poi`;
}

export function PROPERTY_BOOKING_SITES_URL(propertyId) {
    return `/adminapi/property/${propertyId}/booking-sites`;
}

export function PROPERTY_CANCELATION_POLICIES_URL(propertyId) {
    return `/adminapi/property/${propertyId}/cancelation-policies`;
}

export function PROPERTY_OWNER_LIST_URL(propertyId) {
    return `/adminapi/property/${propertyId}/owner-list`;
}

export function PROPERTY_OWNER_URL(propertyId, ownerId) {
    if (ownerId === null) {
        return `/adminapi/property/${propertyId}/owner`;
    }
    return `/adminapi/property/${propertyId}/owner/${ownerId}`;

}

export const PROPERTY_NEW_OWNER_CONTACT_URL = 'adminapi/contact'

export function PROPERTY_EDIT_NEW_OWNER_CONTACT_URL(contactId) {
    return `adminapi/contact/${contactId}`;
}

export const PROPERTY_OWNER_TYPE_URL = 'adminapi/contact/object-type-event'
export const PROPERTY_OWNER_CHANNEL_URL = 'adminapi/contact/channel'

export function PROPERTY_AMENITIES_URL(propertyId) {
    return `/adminapi/property/${propertyId}/amenities`;
}

export function PROPERTY_DESCRIPTIONS_URL(propertyId) {
    return `/adminapi/property/${propertyId}/descriptions`;
}

export function PROPERTY_DECLINED_CC_ACTIONS_URL(propertyId) {
    return `/adminapi/property/${propertyId}/declined-credit-card-actions`;
}

export function PROPERTY_PROMOTION_LIST_URL(propertyId) {

    return `/adminapi/property/${propertyId}/list-promotion`;
}

export function PROPERTY_PROMOTION_EDIT_URL(id, promotionId) {
    return `/adminapi/property/${id}/promotion/${promotionId}`;
}

export function PROPERTY_PROMOTION_RATE_PLAN_MAPPING_URL(id, promotionId) {
    return `/adminapi/property/${id}/promotion-rate-plan-mapping/${promotionId}`;
}

export function PROPERTY_PROMOTION_URL(id) {
    return `/adminapi/property/${id}/promotion`;
}

export function PROPERTY_RM_SETUP_LIST_URL(id) {
    return `/adminapi/property/${id}/rm-setup-list`
}

export function PROPERTY_RM_SETUP_EDIT_URL(id, collectionId) {
    return `/adminapi/property/${id}/rm-setup/${collectionId}`;

}

export function PROPERTY_RM_SETUP_RATE_PLAN_MAPPING(id, collectionId) {
    return `/adminapi/property/${id}/rm-setup-rate-plan-mapping/${collectionId}`;
}

export function PROPERTY_RM_SETUP_PARAMETER_URL(id) {
    return `/adminapi/property/${id}/rm-setup-parameters`
}

export function PROPERTY_RM_SETUP_URL(id) {
    return `/adminapi/property/${id}/rm-setup`
}

// Unit endpoints
export const UNIT_URL = '/adminapi/unit'
export const UNIT_LIST_URL = '/adminapi/unit-list-paginate'
export const UNIT_LIST_PAGINATE_URL = '/adminapi/unit-list-paginate'

export function UNIT_ID_URL(id) {
    return `/adminapi/unit/${id}`;
}

export function UNIT_AMENITY_URL(id) {
    return `/adminapi/unit/${id}/amenity`;
}

export function UNIT_DESCRIPTIONS_URL(id) {
    return `/adminapi/unit/${id}/descriptions`;
}

export function UNIT_COMMISSIONS_URL(id) {
    return `/adminapi/unit/${id}/commissions`;
}

export function UNIT_COPY_COMMISSIONS_URL(id) {
    return `/adminapi/unit/${id}/copy-commissions`;
}

export function UNIT_FEES_URL(id) {
    return `/adminapi/unit/${id}/fee-list`;
}

export function UNIT_FEE_DELETE_URL(id) {
    return `/adminapi/unit/${id}/fee`;

}

export function UNIT_DAMAGE_DEPOSIT_URL(id) {
    return `/adminapi/unit/${id}/damage-deposit`;
}

export function PROPERTY_STATUS_URL(propertyId) {
    return `/adminapi/property/${propertyId}/status`;
}

export function PROPERTY_STATUS_VALIDATE_URL(propertyId, statusTransitionId) {
    return `/adminapi/property/${propertyId}/status-validate/${statusTransitionId}`
}

export function UNIT_STATUS_URL(unitId) {
    return `/adminapi/unit/${unitId}/status`;
}

export function STATUS_LIST_URL(objectTypeId) {
    return `/adminapi/status${objectTypeId ? '/' + objectTypeId : ''}`;
}

export function UNIT_AVAILIBILITY_URL(unitId) {
    return `/adminapi/unit/${unitId}/availability`;
}
export function SET_PRICING_LOS_OCCUPANCY_URL(unitId) {
 return   `/adminapi/unit/${unitId}/set-price-from-los-occupancy`;
}

// User endpoints
export const USER_URL = '/adminapi/user'
export const CLIENT_TYPE_URL = '/adminapi/crm/client-type'
export const USER_LIST_URL = '/adminapi/user-list'


// Pricing endpoints
export const PRICING_LOS_LIST_URL = 'adminapi/pricing/los-list'
export const PRICING_LOS_URL = 'adminapi/pricing/los'


export function PRICING_LOS_EDIT_URL(id) {
    return `/adminapi/pricing/los/${id}`
}

export const PRICING_OCCUPANCY_LIST_URL = 'adminapi/pricing/occupancy-list'
export const PRICING_OCCUPANCY_URL = 'adminapi/pricing/occupancy'

export function PRICING_OCCUPANCY_EDIT_URL(id) {
    return `/adminapi/pricing/occupancy/${id}`
}
export const PRICING_SETUP_VALIDATE_URL = 'adminapi/pricing/setup/validate'

export const PRICING = 'adminapi/pricing'
export const PRICING_RATE_PLAN_LIST_URL = 'adminapi/pricing/rate-plan-list'
export const PRICING_RATE_PLAN_URL = 'adminapi/pricing/rate-plan'
export const PRICING_RATE_PLAN_COPY_URL = 'adminapi/pricing/rate-plan-copy'
export const PRICING_RATE_PLAN_LIST_COPY_URL = 'adminapi/pricing/rate-plan-list-copy'
export const PRICING_RATE_PLAN_LIST_LINKED_VALUE_URL = 'adminapi/pricing/rate-plan-list-linked-value'
export const PRICING_RATE_PLAN_UNIT_LIST_URL = 'adminapi/pricing/rate-plan-unit-list'
export const PRICING_RATE_PLAN_TYPE = 'adminapi/pricing/rate-plan-type'
export const PRICING_IMPORT_PRICES_URL = PRICING + '/import-file'
export const PRICING_IMPORT_DAILY_PRICES_URL = PRICING + '/daily-prices-import-file'
export const PRICING_DOWNLOAD_TEMPLATE_URL = PRICING + '/download-template'
export const BULK_RELATIVE_PRICE_UPLOADER_URL = UNIT_URL + '/bulk-relative-price-uploader'


export function PRICING_RATE_PLAN_EDIT_URL(id) {
    return `/adminapi/pricing/rate-plan/${id}`
}

export const CHARGE_PROFILE_LIST_URL = 'adminapi/pricing/charge-profile-list'
export const SALES_PERIOD_LIST_URL = 'adminapi/pricing/sales-period-list'

export function SALES_PERIOD_DELETE_URL(unit_id, id) {
    return `/adminapi/pricing/${unit_id}/sales-period/${id}`
}
export function SALES_PERIOD_SETUP_URL(id) {
    return `/adminapi/pricing/sales-period-setup/${id}`
}

export function PRP_SETUP_URL(id) {
    return `/adminapi/property/${id}/prp-setup`

}
export function GET_BULK_DATA(id) {
    return `/adminapi/property/${id}/bulk-calendar`
}

export const RATE_MANAGER_ACQUISITION = 'adminapi/rate-manager/acquisition'
export const RATE_MANAGER_FILTER = 'adminapi/rate-manager/filter'
export const RATE_MANAGER = 'adminapi/rate-manager'


export function UNIT_PRICING_URL(id) {
    return `/adminapi/pricing/${id}`
}

export function DISTRIBUTION_RATE_MAP_URL(distributionId, unitId) {
    return `/adminapi/distribution/${distributionId}/rate-plan-map/${unitId}`
}

export function UNIT_MAPPING_URL(unitId) {
    return `/adminapi/unit/${unitId}/mapping`
}

export const UNIT_MAPPING_LIST_URL = 'adminapi/distribution/unit-mapping'

export function DISTRIBUTION_UNIT_MAP_DELETE_URL(distributionId, id) {
    return `/adminapi/distribution/${distributionId}/unit-map/${id}`
}

export function DISTRIBUTION_PROPERTY_MAP_URL(distributionId, propertyId) {
    return `/adminapi/distribution/${distributionId}/property-map/${propertyId}`
}

export function DISTRIBUTION_PROPERTY_MAP_DELETE_URL(distributionId, id) {
    return `/adminapi/distribution/${distributionId}/property-map/${id}`
}

export const CHANNEL_MANAGER_EXTERNAL_UNIT_LIST_URL = 'adminapi/channel-manager/unit-list'
export const CHANNEL_MANAGER_EXTERNAL_RATE_PLAN_LIST_URL = 'adminapi/channel-manager/rate-plan-list'
export const CHANNEL_MANAGER_UNIT_PUBLISH = 'adminapi/channel-manager/unit/publish'
export const CHANNEL_MANAGER_PROPERTY_PUBLISH = 'adminapi/channel-manager/property/publish'
export const CHANNEL_MANAGER_PROPERTY_STATUS = 'adminapi/channel-manager/property/status'
export const CHANNEL_MANAGER_OBJECT_STATUS = 'adminapi/channel-manager/object/status'
export const CHANNEL_MANAGER_DISTRIBUTION_QUEUE_URL = 'adminapi/channel-manager/queue'
export const CHANNEL_MANAGER_DISTRIBUTION_QUEUE_FILTER_URL = 'adminapi/channel-manager/queue-filter-data'
export const CHANNEL_MANAGER_DISTRIBUTION_SETUP_PARAMETERS = 'adminapi/channel-manager/setup-parameters'
export const CHANNEL_MANAGER_DISTRIBUTION_SETUP_PARAMETER_LIST = 'adminapi/channel-manager/setup-parameter-list'
export const CHANNEL_MANAGER = 'adminapi/channel-manager'
export const CHANNEL_MANAGER_STATUS_OBJECT_LIST_URL = CHANNEL_MANAGER + '/status/object-list'


// cm setup property
// activation

export const CHANNEL_MANAGER_ACTIVATION_PROPERTY_URL = 'adminapi/channel-manager/activation/property'
export const CHANNEL_MANAGER_ACTIVATION_UNIT_URL = 'adminapi/channel-manager/activation/unit'
export const CHANNEL_MANAGER_ACTIVATION_UNIT_SETUP_VALIDATION_URL = 'adminapi/channel-manager/activation/unit/setup-validation'

//cm service
export const CHANNEL_MANAGER_PROPERTY_CREATE = 'cm/product/property/create'
export const CHANNEL_MANAGER_PROPERTY_SYNC = 'cm/product/property/sync'
export const CHANNEL_MANAGER_UNIT_CREATE = 'cm/product/unit/create'
export const CHANNEL_MANAGER_CM_UNIT_SYNC = 'cm/product/unit/sync'
export const CHANNEL_MANAGER_PULL_FEED = 'cm/uncommon/ical/unit/pull-feed'
export const CHANNEL_MANAGER_BOOKING_INVITE_URL = 'cm/uncommon/booking/partner/invite'
export const CHANNEL_MANAGER_BOOKING_RESEND_INVITE_URL = 'cm/uncommon/booking/partner/resend-invite'
export const CHANNEL_MANAGER_GET_LICENCES_URL = 'cm/uncommon/booking/licences'
export const CHANNEL_MANAGER_PROPERTY_DOWNLOAD_GALLERY_URL = 'cm/uncommon/property/download-gallery'


export function DISTRIBUTION_RATE_PLAN_MAP_DELETE(distributionId, id) {

    return `/adminapi/distribution/${distributionId}/rate-plan-map/${id}`
}

export function PRP_SETUP_UNIT_PRICE_URL(unitId) {
    return `/adminapi/unit/${unitId}/price-prp`
}

export function CHANNEL_PHOTO(id) {
    return `${API_CONFIG.API_URL}/${API_URL}/channel-icon/${id}`
}

export function DISTRIBUTION_PHOTO(id) {
    return `${API_CONFIG.API_URL}/${API_URL}/distribution-icon/${id}`
}

export const RESTRICTION_URL = 'adminapi/restriction'
export const RESTRICTION_DEFAULT_URL = RESTRICTION_URL + '/default'

export function ROOM_STATUS_URL(unitId) {
    return `/adminapi/unit/${unitId}/sales-regulation`
}

export const INVOICE_URL = 'adminapi/invoice'
export const INVOICE_TYPE_URL = 'adminapi/invoice-type'
export const INVOICE_CREATION_SETUP_URL = 'adminapi/invoice-creation-setup'
export const INVOICE_ITEM_CREATION_SETUP_URL = 'adminapi/invoice-item-creation-setup'

export const FINANCE_URL = 'adminapi/finance'
export const FINANCE_PARTNER_URL = FINANCE_URL + '/partner'
export const FINANCE_SETUP_URL = FINANCE_URL + '/setup'
export const FINANCE_PARTNER_LIST_URL = 'adminapi/finance/partner/fetch-partner-list'
export const FINANCE_CASH_REGISTER_SETUP_URL = 'adminapi/cash-register/setup'
export const FINANCE_CASH_REGISTER_LIST_URL = 'adminapi/cash-register/lookup'
export const FINANCE_USER_CASH_REGISTER_SETUP = 'adminapi/user/cash-register-setup'

export const CURRENCY_EXCHANGE_URL= 'adminapi/currency-exchange'
export const CURRENCY_EXCHANGE_FIXED_URL = `${CURRENCY_EXCHANGE_URL}/fixed`
export const FINANCE_PAYMENT_ORDER_URL = FINANCE_URL + '/payment-order'
export const FINANCE_PAYMENT_MESSAGE_URL = FINANCE_URL + '/payment-message'

export const TRANSACTION_URL = 'adminapi/transaction'
export const PAYOUT_REPORT_FETCH_URL = ACCOMMODATION_RESERVATION + '/bookings/pbb'
export const PAYOUT_REPORT_EXPORT_URL = ACCOMMODATION_RESERVATION + '/bookings/pbb/export'

export const NO_TRANSACTION_REPORT_FETCH_URL = ACCOMMODATION_RESERVATION + '/bookings/no-transaction'
export const NO_TRANSACTION_REPORT_EXPORT_URL = ACCOMMODATION_RESERVATION + '/bookings/no-transaction/export'

export function FINANCE_PARTNER_EDIT_URL(id) {
    return `/adminapi/finance/partner/${id}`
}

export function FINANCE_PARTNER_MAPPING_URL(id) {
    return `/adminapi/finance/partner/${id}/mapping`
}
export function FINANCE_USER_CASH_REGISTER_ENTITY_SETUP(register_setup){
    return `/adminapi/cash-register/${register_setup}/entity-setup`
}
export const GUEST_URL = 'adminapi/guest'
export const GUEST_FORM_DATA_URL = 'adminapi/guest/form'

export function GUEST_ACCESS_DATA_URL(id) {
    return `adminapi/property/${id}/guest-access-data`
}


// TAG endpoints
export function TAG_URL(id) {
    return `/adminapi/tag/${id}`
}
export const TAG_URL_STRING = `/adminapi/tag`


// CM Endpoints
const CM_BASE_URL = '/cm/'
export const CM_AVAILABILITY_SYNC = `${CM_BASE_URL}availability/sync`
export const CM_PRICING_SYNC = `${CM_BASE_URL}rate/sync`
export const CM_RESTRICTION_SYNC = `${CM_BASE_URL}restriction/sync`
export const CM_ROOM_STATUS_SYNC = `${CM_BASE_URL}sales-regulation/sync`


// Currency endpoints

export const CURRENCY_URL = 'adminapi/currency'

// Subscription endpoints

export const SUBSCRIPTION_URL = 'adminapi/subscription'

export function COMPANY_SUBSCRIPTION_URL(id) {
    return `adminapi/company/${id}/subscription`
}

// Booking engine
export const BOOKING_ENGINE = 'adminapi/booking-engine'

//System data
export const SYSTEM_DATA_URL = 'adminapi/system'
export const SYSTEM_SETTINGS_URL = SYSTEM_DATA_URL + '/settings'

//Access
export const REGISTER_URL = 'adminapi/company/register'
export const USER_PASSWORD_URL = 'adminapi/user/password'

// Component Access
export const ACCESS_CONTROL_PRODUCT_MANAGEMENT = 'adminapi/component-access-control/product-management'
export const ACCESS_CONTROL_PRODUCT_MANAGEMENT_UNIT = 'adminapi/component-access-control/product-management-unit'
export const ACCESS_CONTROL_RATE_PLAN = 'adminapi/component-access-control/rate-plan'
export const ACCESS_CONTROL_PROPERTY = 'adminapi/component-access-control/property'
export const ACCESS_CONTROL_PROPERTY_NAVIGATION = 'adminapi/component-access-control/property-navigation'
export const ACCESS_CONTROL_UNIT_NAVIGATION = 'adminapi/component-access-control/unit-navigation'
export const ACCESS_CONTROL_UNIT_GENERAL = 'adminapi/component-access-control/unit-general'
export const ACCESS_CONTROL_ACCOMMODATION_RESERVATION = 'adminapi/component-access-control/accommodation-reservation'
export const ACCESS_CONTROL_SUBSCRIPTION = 'adminapi/component-access-control/subscription'
export const ACCESS_CONTROL_ADDITIONAL_SETUP = 'adminapi/component-access-control/additional-setup'
export const ACCESS_CONTROL_PROPERTY_ACTIVATION_WIZARD = 'adminapi/component-access-control/property-activation-wizard'
export const DASHBOARD = 'adminapi/dashboard'
export const ACCESS_CONTROL_TRANSACTION_ACTION = 'adminapi/component-access-control/transaction-action'
export const ACCESS_CONTROL_ACCOMMODATION_RESERVATION_REPORTING = 'adminapi/component-access-control/accommodation-reservation-reporting'
export const ACCESS_CONTROL_USER_NAVIGATION = 'adminapi/component-access-control/user-navigation'
export const ACCESS_CONTROL_SYSTEM_SETTINGS_NAVIGATION = 'adminapi/component-access-control/system-setup-navigation'
export const ACCESS_CONTROL_CM_SETUP_PARAMETER = 'adminapi/component-access-control/cm-setup-parameter'
export const ACCESS_CONTROL_PARTNER = 'adminapi/component-access-control/partner'
export const ACCESS_CONTROL_MAIN_NAVIGATION = 'adminapi/component-access-control/main-navigation'
export const ACCESS_CONTROL_INVOICE = 'adminapi/component-access-control/invoice'
export const ACCESS_CONTROL_MAIN_CALENDAR = 'adminapi/component-access-control/main-calendar'
export const ACCESS_CONTROL_SYSTEM_FINANCE_SETUP= 'adminapi/component-access-control/system-finance-setup'
export const ACCESS_CONTROL_BULK_CALENDAR= 'adminapi/component-access-control/bulk-calendar'
export const ACCESS_CONTROL_OWNER= 'adminapi/component-access-control/owner'
export const ACCESS_CONTROL_DASHBOARD = 'adminapi/component-access-control/dashboard'
export const ACCESS_CONTROL_FEATURE_SETUP = 'adminapi/component-access-control/feature_setup'
export const ACCESS_CONTROL_REVENUE_MANAGER = 'adminapi/component-access-control/revenue-manager'
export const ACCESS_CONTROL_BULK_RATE_PLAN = 'adminapi/component-access-control/bulk-rate-plan'
export const ACCESS_CONTROL_WEBSITE = 'adminapi/component-access-control/website'
export const ACCESS_CONTROL_DISTRIBUTION = 'adminapi/component-access-control/distribution'
export const ACCESS_CONTROL_WEBSITE_PAGES = 'adminapi/component-access-control/website-pages'
export const ACCESS_CONTROL_CANCELLATION_POLICY = 'adminapi/component-access-control/cancellation-policy'
export const ACCESS_CONTROL_COMPANY_NAVIGATION = 'adminapi/component-access-control/company-navigation'
export const ACCESS_CONTROL_COMPANY = 'adminapi/component-access-control/company'
export const ACCESS_CONTROL_CRM = 'adminapi/component-access-control/crm'
export const ACCESS_CONTROL_ADD_RETURN_TRANSFER = 'adminapi/component-access-control/transfer-reservation'



// Template events
export const TEMPLATE_EVENT_URL = 'adminapi/template-events'

// Event template
export const EVENT_TEMPLATE_URL = API_URL + '/event-template'

export const EVENT_URL = API_URL + '/event'

export function EVENT_TEMPLATE_LINK_SYSTEM_TEMPLATE_URL(id) {
    return `adminapi/event-template/${id}/link-system-template`
}
// Workflow
export const WORKFLOW_URL = API_URL + '/workflow'

// Additional setup
export const ADDITIONAL_SETUP_URL = COMPANY + '/additional-setup'

//Transfer endpoints
export const TRANSFER_URL = 'adminapi/transfer'

// Activity endpoints
export const ACTIVITY_RESERVATION_URL = 'adminapi/activity/reservation'


// inbox
export const INBOX = "/adminapi/communication/thread"

export const CALCULATION_URL = FINANCE_URL + '/calculation'


//review

export const  REVIEW_URL ='/adminapi/communication/review'

// health montor

export const VALIDATION_URL ='/adminapi/product/validate'

export const BOOKING_WINDOW = '/adminapi/booking-window'
export const BOOKING_WINDOW_SETUP_TYPE = '/adminapi/booking-window-setup-type'
export const BOOKING_WINDOW_SETUP = '/adminapi/booking-window-setup'

export const REVENUE_MANAGER = '/adminapi/revenue-manager'
export const REVENUE_MANAGER_FILTER_UNIT = '/adminapi/revenue-manager/filter/unit'
export function REVENUE_MANAGER_UNIT_ID_URL(id) {
    return `/adminapi/revenue-manager/unit/${id}/revenue-manager-setup`;
}

//website endpoints

export const DB_WEBSITE_URL  = '/adminapi/direct-booking/website'


// Payment gateway endpoints
export const PAYMENT_GATEWAY_URL = '/adminapi/payment-gateway'
export const PAYMENT_GATEWAY_LIST_URL = PAYMENT_GATEWAY_URL + '/list'
export const PAYMENT_GATEWAY_PCIBOOKING_URL = PAYMENT_GATEWAY_URL + '/pci-booking'
export const PAYMENT_GATEWAY_PCIBOOKING_GATEWAY_LIST_URL = PAYMENT_GATEWAY_PCIBOOKING_URL + '/list'
export const PAYMENT_GATEWAY_SETUP_URL = PAYMENT_GATEWAY_URL + '/setup'

// Description endpoints
export const DESCRIPTION_URL = '/adminapi/description'

//Promo codes endpoints
export const PROMO_CODES_URL = '/adminapi/promo-code'

//Marketplace
export const MARKETPLACE_URL = '/adminapi/marketplace'

//Appearance
export const APPEARANCE_URL = '/adminapi/appearance'

// Guest backoffice
export const GUEST_BACKOFFICE_SETUP_URL = '/adminapi/guest-backoffice-setup'

// Property feature setup
export function PROPERTY_FEATURE_SETUP_URL(id){
    return `/adminapi/property/${id}/feature-setup`;
}
