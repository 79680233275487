// Route names constants
export default {
    RN_HOME: "Home",
    RN_DASHBOARD: "Dashboard",
    RN_LOGIN: "Login",
    RN_W_LOGIN: "W_Login",
    RN_404: "Page404",
    RN_REGISTER:"Register",
    RN_ACTIVATE_USER:"ActivateUser",
    RN_RESET_PASSWORD:"ResetPassword",
    RN_W_RESET_PASSWORD:"WResetPassword",
    RN_CHANGE_PASSWORD:"ChangePassword",
    RN_CHANGE_EXPIRED_PASSWORD:"ChangeExpiredPassword",

    RN_USER_ROOT: "UserRoot",
    RN_USER: "User",
    RN_USER_BASIC: "UserBasic",
    RN_USER_ACCOUNT: "UserAccount",
    RN_USER_ROLES: "UserRoles",
    RN_USER_LIST: "UserList",
    RN_USER_NEW: "UserNew",
    RN_USER_NOTIFICATION: "UserNotification",
    RN_USER_CASH_REGISTER_SETUP:"UserRegisterSetup",

    RN_SYSTEM: 'SystemRoot',
    RN_COMPANY_LIST: "CompanyList",
    RN_COMPANY_ROOT: "CompanyRoot",
    RN_COMPANY: "Company",
    RN_COMPANY_BASIC: "CompanyBasic",
    RN_COMPANY_CONTACTS: "CompanyContacts",
    RN_COMPANY_SYSTEM: "CompanySystem",
    RN_COMPANY_NEW: "CompanyNew",
    RN_COMPANY_SUBSCRIPTION: "CompanySubscription",
    RN_COMPANY_INVOICE_LIST: "CompanyInvoiceList",
    RN_COMPANY_WHITELABEL: "CompanyWhitelabel",
    RN_COMPANY_SETTINGS: "CompanySettings",

    RN_PROPERTY_LIST: "PropertyList",
    RN_PROPERTY: "Property",
    RN_PROPERTY_NEW: "PropertyNew",
    RN_PROPERTY_OVERVIEW: "PropertyOverview",
    RN_PROPERTY_BASIC: "PropertyBasic",
    RN_PROPERTY_LOCATION: "PropertyLocation",
    RN_PROPERTY_POI: "PropertyPoi",
    RN_PROPERTY_POLICY_OTHER: "PropertyPolicyOther",
    RN_PROPERTY_BOOKING_SITES: "PropertyBookingSites",
    RN_PROPERTY_CANCELATION_POLICIES: "PropertyCancelationPolicies",
    RN_PROPERTY_AMENITIES: "PropertyAmenities",
    RN_PROPERTY_DESCRIPTIONS: "PropertyDescriptions",
    RN_PROPERTY_CRM: "PropertyCrm",
    RN_PROPERTY_GUEST_MONTHLY_REPORT: "PropertyGuestMonthlyReport",
    RN_PROPERTY_GUEST_CHECKIN:"PropertyGuestCheckIn",
    RN_PROPERTY_TRANSFER:"PropertyTransfer",
    RN_PROPERTY_SETTINGS:"PropertySettings",

    RN_PROPERTY_PROMOTIONS: "PropertyPromotions",
    RN_PROPERTY_OWNER_RATE_MANAGER: "PropertyOwnerRateManager",

    RN_PROPERTY_GALLERY: "PropertyGallery",
    RN_NEW_PROPERTY_GALLERY: "NewPropertyGallery",
    RN_PROPERTY_DECLINED_CC_ACTIONS: "PropertyDeclinedCcActions",
    RN_PROPERTY_POLICIES: "PropertyPolicies",
    RN_PROPERTY_CONTACTS: "PropertyContacts",

    RN_PROPERTY_OWNER_LIST: "PropertyOwnerList",
    RN_PROPERTY_OWNER_EDIT: "PropertyOwnerEdit",

    RN_PROPERTY_OWNER_BASIC: "PropertyOwnerBasic",
    RN_PROPERTY_OWNER_DOCUMENTS: "PropertyOwnerDocuments",
    RN_PROPERTY_OWNER_CONTACTS: "PropertyOwnerContacts",
    RN_PROPERTY_OWNER_USER: "PropertyOwnerUser",
    RN_PROPERTY_OWNER_FINANCE: "PropertyOwnerFinance",

    RN_PROPERTY_UNIT_LIST: "PropertyUnitList",
    RN_PROPERTY_UNIT_EDIT: "PropertyUnitEdit",


    RN_PROPERTY_CHANNEL_MANAGER_DISTRIBUTION: "PropertyChannelManagerDistribution",
    RN_PROPERTY_CHANNEL_MANAGER_EDIT: "PropertyChannelManagerEdit",
    RN_PROPERTY_CHANNEL_MANAGER_PRODUCT_MANAGEMENT: "PropertyChannelManagerProductManagement",
    RN_PROPERTY_CHANNEL_MANAGER_SYNCHRONIZATION_LOG: "PropertyChannelManagerSynchronizationLog",
    RN_PROPERTY_CHANNEL_MANAGER_SYNCHRONIZATION_LOG_2: "PropertyChannelManagerSynchronizationLog2",
    RN_PROPERTY_CHANNEL_MANAGER_SETUP: "PropertyChannelManagerSetup",

    RN_PROPERTY_BOOKING_ENGINE: "PropertyBookingEngine",
    RN_PROPERTY_ICAL:"PropertyIcal",

    RN_PROPERTY_CALENDAR: "PropertyCalendar",
    RN_PROPERTY_CALENDAR_OPT: "PropertyCalendarOpt",
    RN_PROPERTY_CALENDAR_OPT_TEST: "PropertyCalendarOptTest",
    RN_PROPERTY_MAIN_CALENDAR: "PropertyMainCalendar",

    RN_PROPERTY_EVENT_TEMPLATES: "MailTemplates",
    RN_PROPERTY_DEFAULT_RATES_RESTRICTIONS:"PropertyDefaultRatesRestrictions",

    RN_PROPERTY_USER_ACCESS: "UserAccess",


    RM_PROPERTY_UNIT_BASIC: 'PropertyUnitBasic',
    RM_PROPERTY_UNIT_GALLERY: 'PropertyUnitGallery',
    RM_PROPERTY_UNIT_AMENITY: 'PropertyUnitAmenity',
    RM_PROPERTY_UNIT_HEADLINE: 'PropertyUnitHeadline',
    RM_PROPERTY_UNIT_DESCRIPTIONS: 'PropertyUnitDescriptions',
    RM_PROPERTY_UNIT_COMMISSIONS: 'PropertyUnitCommissions',
    RM_PROPERTY_UNIT_FEES: 'PropertyUnitFees',
    RM_PROPERTY_UNIT_DAMAGE_DEPOSIT: 'PropertyUnitDamageDeposit',
    RM_PROPERTY_UNIT_KEY_PICKUP: 'PropertyUnitKeyPickup',
    RM_PROPERTY_UNIT_PRICES:"PropertyUnitPrices",
    RM_PROPERTY_UNIT_RATE_PLANS:"PropertyUnitRatePlans",
    RM_PROPERTY_UNIT_PRICING_GENERAL_SETUP:"PropertyUnitPricingGeneralSetup",
    RM_PROPERTY_UNIT_CONTINGENT_LIST:'PropertyUnitContingentList',
    RM_PROPERTY_UNIT_DEFAULT_RESTRICTIONS:'PropertyUnitDefaultRestrictions',
    RN_PROPERTY_REVIEW:"PropertyReview",

    RN_GUEST_LIST: 'GuestList',

    RN_RESERVATION: "Reservation",
    RN_RESERVATION_ACCOMMODATION_LIST: 'ReservationAccommodationList',
    RN_RESERVATION_ACCOMMODATION_ID: 'ReservationAccommodationId',
    RN_RESERVATION_ACCOMMODATION: 'ReservationAccommodation',
    RN_RESERVATION_ACCOMMODATION_GENERAL: 'ReservationAccommodationGeneral',
    RN_RESERVATION_ACCOMMODATION_GUESTS: 'ReservationAccommodationGuests',
    RN_RESERVATION_ACCOMMODATION_NOTES: 'ReservationAccommodationNotes',
    RN_RESERVATION_ACCOMMODATION_INVOICES: 'ReservationAccommodationInvoices',
    RN_RESERVATION_ACCOMMODATION_CC: 'ReservationAccommodationCC',
    RN_RESERVATION_ACCOMMODATION_FINANCE: 'ReservationAccommodationFinance',
    RN_RESERVATION_ACCOMMODATION_FINANCE_DASHBOARD: 'ReservationAccommodationFinanceDashboard',
    RN_RESERVATION_ACCOMMODATION_CONNECTED_SERVICES: 'ReservationAccommodationConnectedServices',
    RN_RESERVATION_ACCOMMODATION_INBOX: 'ReservationAccommodationInbox',
    RN_RESERVATION_ACCOMMODATION_SCHEDULER: 'ReservationAccommodationScheduler',

    RN_RESERVATION_ACTIVITY: "ReservationActivity",
    RN_RESERVATION_ACTIVITY_LIST: "ReservationActivityList",

    RN_SETUP: "Setup",
    RN_SETUP_GENERAL: "SetupGeneral",
    RN_SETUP_FINANCE_VAT: "SetupFinanceVat",
    RN_SETUP_FINANCE_ITEM_DESCRIPTION: "SetupFinanceItemDescription",
    RN_SETUP_FINANCE_INVOICE_TYPES: "SetupFinanceInvoiceTypes",
    RN_SETUP_FINANCE_INVOICE_CREATION: "SetupFinanceInvoiceCreation",
    RN_SETUP_FINANCE_CE_FIXED: "SetupFinanceCurrencyExchangeFixed",
    RN_SETUP_FINANCE_PARTNERS: "SetupFinancePartners",
    RN_SETUP_SYSTEM_TEMPLATES_EMAIL_TEMPLATES: "SetupSystemTemplatesEmailTemplates",
    RN_SETUP_SYSTEM_TEMPLATES_CANCELLATION_POLICIES: "SetupSystemTemplatesCancellationPolicies",
    RN_SETUP_SYSTEM_TEMPLATES_SALES_PERIODS: "SetupSystemTemplatesSalesPeriods",
    RN_SETUP_REGISTER_SETUP: "SetupRegisterSetup",
    RN_SETUP_DESTINATION_TAG: "SetupDestinationTag",
    RN_SETUP_DESTINATION_TAG_GENERAL: "SetupDestinationTagGeneral",
    RN_SETUP_DESTINATION_TAG_COUNTRY:"SetupDestinationTagCountry",
    RN_SETUP_DESTINATION_TAG_COUNTRY_ID:"SetupDestinationTagCountryId",
    RN_SETUP_DESTINATION_TAG_REGION: "SetupDestinationTagRegion",
    RN_SETUP_DESTINATION_TAG_REGION_ID: "SetupDestinationTagRegionId",
    RN_SETUP_DESTINATION_TAG_GENERAL_REGION: "SetupDestinationTagRegionGeneral",
    RN_SETUP_DESTINATION_TAG_CITY: "SetupDestinationTagCity",
    RN_SETUP_CUSTOM_TAG: "SetupCustomTag",
    RN_SETUP_WHITELABEL: "SetupWhitelabel",
    RN_SETUP_EVENTS : "SetupEvents",
    RN_SETUP_EVENTS_SYSTEM: "SetupEventsSystem",
    RN_SETUP_SYSTEM_EVENTS_PRESET : "SetupEventsPreset",
    RN_SETUP_SYSTEM_EVENTS_PROPERTY_OVERVIEW : "SetupEventsPropertyOverview",


    PUBLIC_PROPERTY_RESET: "PropertyResetPage",

    RN_INVOICE: "Invoice",
    RN_INVOICE_LIST: "InvoiceList",
    RN_OWNER_INVOICE_LIST: "OwnerInvoiceList",
    RN_TRANSACTION_LIST: "TransactionList",
    RN_PAYOUT_REPORT_LIST: "PayoutReportList",
    RN_NO_TRANSACTION_REPORT_LIST: "NoTransactionReportList",

    RN_PAYMENT_ORDER: "PaymentOrder",
    RN_PAYMENT_ORDER_LIST: "PaymentOrderList",

    RN_TRANSFER: "Transfer",
    RN_RESERVATION_TRANSFER_LIST: "TransferReservationList",
    RN_CHANNEL_MANAGER:"ChannelManager",
    RN_CHANNEL_MANAGER_DASHBOARD:"ChannelManagerDashboard",
    RN_CHANNEL_MANAGER_LOG:"ChannelManagerLog",
    RN_CHANNEL_MANAGER_SETUP:"ChannelManagerSetup",

    RN_PAYMENT_GATEWAY: "PaymentGateway",
    RN_PAYMENT_GATEWAY_LIST: "PaymentGatewayList",

    RN_INTEGRATIONS:"Integrations",

    RN_PAYMENT_MESSAGE: "PaymentMessage",
    RN_PAYMENT_MESSAGE_LIST: "PaymentMessageList",

    RN_INBOX: "Inbox",
    RN_CALENDAR: "Calendar",
    RN_CALENDAR_TEST: "Calendar test",
    RN_CALENDAR_MULTI_UNIT_VIEW: "Multi unit view",
    RN_CALENDAR_MONTHLY_VIEW: "Monthly view",
    RN_CALENDAR_SINGLE_UNIT: "Single unit",

    RN_OWNER_CALCULATION: 'OwnerCalculation',
    RN_OWNER_CALCULATION_LIST: 'OwnerCalculationList',

    RN_ACCOMMODATION_REVIEW: 'AccommodationReview',
    RN_ACTIVITY_REVIEW: 'ActivityReview',
    RN_TRANSFER_REVIEW: 'TransferReview',

    RN_HEALTH_MONITOR_ROOT: "HealthMonitorRoot",
    RN_HEALTH_MONITOR: "HealthMonitor",


    RN_DIRECT_BOOKING_ACTIVATION:"DirectBookingActivation",
    RN_DIRECT_BOOKING:"DirectBooking",
    RN_DIRECT_BOOKING_WEBSITE_ROOT: "DirectBookingWebsiteRoot",
    RN_DIRECT_BOOKING_WEBSITE_OVERVIEW: "DirectBookingWebsitesOverview",
    RN_DIRECT_BOOKING_WEBSITE_GENERAL: "DirectBookingWebsitesGeneral",
    RN_DIRECT_BOOKING_WEBSITE_LIST:"DirectBookingWebsitesList",
    RN_DIRECT_BOOKING_WEBSITE_RESERVATION: "DirectBookingWebsiteReservation",
    RN_DIRECT_BOOKING_WEBSITE: "DirectBookingWebsite",
    RN_DIRECT_BOOKING_WEBSITE_UNIT_ACTIVATION: "DirectBookingWebsiteUnitActivation",
    RN_DIRECT_BOOKING_SETUP: "DirectBookingSetup",
    RN_DIRECT_BOOKING_WEBSITE_PAGES: "DirectBookingWebsitePages",
    RN_DIRECT_BOOKING_WEBSITE_PAGE: "DirectBookingWebsitePage",
    RN_DIRECT_BOOKING_WEBSITE_PAGE_OVERVIEW: "DirectBookingWebsitePageOverview",
    RN_DIRECT_BOOKING_WEBSITE_PAGE_GENERAL: "DirectBookingWebsitePageGeneral",
    RN_DIRECT_BOOKING_WEBSITE_PAGE_CONTENT: "DirectBookingWebsitePageContent",
    RN_DIRECT_BOOKING_WEBSITE_PAGE_FILTERS: "DirectBookingWebsitePageFilters",
    RN_DIRECT_BOOKING_WEBSITE_NAVIGATION: "DirectBookingWebsiteNavigation",
    RN_DIRECT_BOOKING_WEBSITE_GALLERY: "DirectBookingWebsiteGallery",
    RN_DIRECT_BOOKING_WEBSITE_AMENITY_CATEGORIES: "DirectBookingWebsiteAmenityCategories",
    RN_DIRECT_BOOKING_WEBSITE_AMENITIES: "DirectBookingWebsiteAmenities",
    RN_DIRECT_BOOKING_WEBSITE_UNIT_TYPE: "DirectBookingWebsiteUnitType",
    RN_DIRECT_BOOKING_WEBSITE_TAGS: "DirectBookingWebsiteTags",
    RN_DIRECT_BOOKING_WEBSITE_INTEGRATIONS: "DirectBookingWebsiteIntegrations",
    RN_DIRECT_BOOKING_PROMO_CODES: "DirectBookingPromoCodes",
    RN_DIRECT_BOOKING_PROMO_CODES_LIST: "DirectBookingPromoCodesList",
    RN_DIRECT_BOOKING_PROMO_CODES_CATEGORY: "DirectBookingPromoCodesCategory",
    RN_DIRECT_BOOKING_WEBSITE_PROMO_CODES: "DirectBookingWebsitePromoCodes",
    RN_DIRECT_BOOKING_MARKETPLACE:"DirectBookingMarketplace",
    RN_DIRECT_BOOKING_MARKETPLACE_LIST: "DirectBookingMarketplaceList",
    RN_DIRECT_BOOKING_MARKETPLACE_GENERAL: "DirectBookingMarketplaceGeneral",
    RN_DIRECT_BOOKING_APPEARANCE: "DirectBookingAppearance",

    RN_REVENUE_MANAGER:"RevenueManager",
    RN_REVENUE_MANAGER_ACTIVATION:"RevenueManagerActivation",
    RN_REVENUE_MANAGER_BOOKING_WINDOW:"RevenueManagerBookingWindow",
    RN_REVENUE_MANAGER_BOOKING_WINDOW_LIST:"RevenueManagerBookingWindowList",
    RN_REVENUE_MANAGER_SETUP:"RevenueManagerSetup",
    RN_REVENUE_MANAGER_SETUP_ACTION_PRICE:"RevenueManagerSetupActionPrice",
    RN_REVENUE_MANAGER_SETUP_ACTION_MIN_STAY:"RevenueManagerSetupActionMinStay",
    RN_REVENUE_MANAGER_SIMILAR_LISTING:"RevenueManagerSimilarListing",
    RN_REVENUE_MANAGER_SIMILAR_LISTING_FILTER:"RevenueManagerSimilarListingFilter",
    RN_REVENUE_MANAGER_SIMILAR_LISTING_UNIT:"RevenueManagerSimilarListingUnit",
    RN_REVENUE_MANAGER_SETUP_ACTION:"RevenueManagerSetupAction",
    RN_REVENUE_MANAGER_SETUP_PROPERTIES:"RevenueManagerSetupProperties",

    RN_SYSTEM_APPEARANCE: "SystemAppearance",
    RN_SYSTEM_APPEARANCE_COLORS: "SystemAppearanceColors",
    RN_SYSTEM_APPEARANCE_FONTS: "SystemAppearanceFonts",
    RN_SYSTEM_APPEARANCE_COLOR_CATEGORY: "SystemAppearanceColorCategory",

    RN_SYSTEM_RESERVATION_PROCESS: "SystemReservationProcess",
    RN_SYSTEM_RESERVATION_OWNER_STATEMENT: "SystemReservationOwnerStatement",
    RN_SYSTEM_RESERVATION_OWNER_STATEMENT_COMPANY: "SystemReservationOwnerStatementCompany",
    RN_SYSTEM_RESERVATION_OWNER_STATEMENT_OWNER: "SystemReservationOwnerStatementOwner",
    RN_SYSTEM_RESERVATION_OWNER_STATEMENT_PROPERTY: "SystemReservationOwnerStatementProperty",
    RN_SYSTEM_RESERVATION_CURRENCY: "SystemReservationCurrency",
    RN_SYSTEM_RESERVATION_CURRENCY_COMPANY: "SystemReservationCurrencyCompany",
    RN_SYSTEM_RESERVATION_CURRENCY_RATE_PLAN: "SystemReservationCurrencyRatePlan",
    RN_SYSTEM_RESERVATION_CURRENCY_PROPERTY: "SystemReservationCurrencyProperty",
    RN_SYSTEM_RESERVATION_PROCESS_NOTE_SETUP: "SystemReservationProcessNoteSetup",
    RN_SYSTEM_RESERVATION_PROCESS_NOTE_SETUP_COMPANY: "SystemReservationProcessNoteSetupCompany",
    RN_SYSTEM_RESERVATION_PROCESS_NOTE_SETUP_OWNER: "SystemReservationProcessNoteSetupOwner",
    RN_SYSTEM_RESERVATION_PROCESS_NOTE_SETUP_PROPERTY: "SystemReservationProcessNoteSetupProperty",
    RN_SYSTEM_RESERVATION_PROCESS_NOTE_SETUP_RATE_PLAN: "SystemReservationProcessNoteSetupRatePlan",
    RN_SYSTEM_RESERVATION_PROCESS_DATA_TRANSITION: "SystemReservationProcessDataTransition",
    RN_SYSTEM_RESERVATION_PROCESS_DATA_TRANSITION_COMPANY: "SystemReservationProcessDataTransitionCompany",
    RN_SYSTEM_RESERVATION_PROCESS_DATA_TRANSITION_OWNER: "SystemReservationProcessDataTransitionOwner",
    RN_SYSTEM_RESERVATION_PROCESS_DATA_TRANSITION_PROPERTY: "SystemReservationProcessDataTransitionProperty",

    RN_SYSTEM_DISTRIBUTION: "SystemDistribution",
    RN_SYSTEM_DISTRIBUTION_GENERAL: "SystemDistributionGeneral",
    RN_SYSTEM_DISTRIBUTION_PROPERTY: "SystemDistributionProperty",
    RN_SYSTEM_DISTRIBUTION_UNIT: "SystemDistributionUnit",
    RN_SYSTEM_DISTRIBUTION_RATE_PLAN: "SystemDistributionRatePlan",
    RN_SYSTEM_DISTRIBUTION_OWNER: "SystemDistributionOwner",
    RN_SYSTEM_WHITELABEL_GENERAL: "SystemWhitelabelGeneral",
    RN_SYSTEM_WHITELABEL_BOOKER_TOOLS_APPEARANCE: "SystemWhitelabelBookerToolsAppearance",
    RN_SYSTEM_WHITELABEL_GUEST_BACKOFFICE_APPEARANCE: "SystemWhitelabelGuestBackofficeAppearance",
    RN_SYSTEM_GUEST_BACKOFFICE: "SystemGuestBackoffice",

    RN_SETUP_FEES: "SetupFees",
    RN_SETUP_FEES_PLATFORM_FEE: "SetupFeesPlatformFee",
    RN_SETUP_FEES_PLATFORM_FEE_DISTRIBUTION: "SetupFeesPlatformFeeDistribution",
    RN_SETUP_FEES_PLATFORM_FEE_DISTRIBUTION_PROPERTY: "SetupFeesPlatformFeeDistributionProperty",
    RN_SETUP_FEES_PLATFORM_FEE_DISTRIBUTION_UNIT: "SetupFeesPlatformFeeDistributionUnit",

}
