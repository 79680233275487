<template>
    <div>
        <app-table-v2
            :selectable="false"
            class="reservation_table"
            :fields="tableFields"
            :http="{methods:'GET', url:NO_TRANSACTION_LIST_URL}"
            :provider="getData"
            :limit-list="[DEFAULT_PAGINATION_LIMIT, 20, 50, 100]"
            @busyToggled="toggleBusy"
            search-event="ge_get_report">

            <template v-slot:reservation_code="data">
                {{data.item.reservation_code}}
            </template>

            <template v-slot:source="data">
                {{data.item.distribution}}
            </template>

            <template v-slot:date="data">
                {{ data.item.check_in_date | date(false) }} - {{ data.item.check_out_date | date }}
            </template>

            <template v-slot:booking_date="data">
                <small class="text-muted d-block">
                    {{ data.item.booking_date | time }}
                </small>
                {{ data.item.booking_date | date }}
            </template>

            <template v-slot:status="data">
                {{ data.item.status }}
            </template>

            <template v-slot:payment_status="data">
                {{ data.item.payment_status }}
            </template>

            <template v-slot:charge_made_by="data">
                {{ data.item.charge_made_by }}
            </template>

            <template v-slot:payment_type="data">
                {{ data.item.payment_type }}
            </template>

            <template v-slot:last_date_to_cancel="data">
                <small class="text-muted d-block">
                    {{ data.item.last_date_to_cancel | time }}
                </small>
                {{ data.item.last_date_to_cancel | date }}
            </template>

            <template v-slot:cancellation_time="data">
                <small class="text-muted d-block">
                    {{ data.item.cancellation_time | time }}
                </small>
                {{ data.item.cancellation_time | date }}
            </template>

            <template v-slot:total_gross="data">
                {{data.item.total_gross}} {{ data.item.channel_currency }}
            </template>

            <template v-slot:total_net="data">
                {{data.item.total_net}} {{ data.item.channel_currency }}
            </template>

            <template v-slot:owner_amount="data">
                {{data.item.owner_amount}} {{ data.item.channel_currency }}
            </template>

            <template v-slot:purchase_amount="data">
                {{data.item.purchase_amount}} {{ data.item.channel_currency }}
            </template>

            <template v-slot:refund_amount="data">
                {{data.item.refund_amount}} {{ data.item.channel_currency }}
            </template>

            <template v-slot:property_name="data">
                {{ data.item.property_name }}
            </template>
        </app-table-v2>
    </div>
</template>

<script>
import AppTableV2 from "@/components/app/AppTableV2";
import {EventBus, GE_TABLE_REFRESH_SEARCH} from "@/shared/EventBus";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {
    DEFAULT_PAGINATION_LIMIT, NO_TRANSACTION_LIST_URL,
} from "@/shared/constants";
import {getNoTransactionReport} from "@/services/finance/no_transaction_report";
import {toast} from "@/shared/plugins/toastr";

export default {
    name: "NoTransactionTable",
    props:{
        filter:{
            type:Object,
        }
    },
    mixins: [getErrorMessage],
    data() {
        return {
            DEFAULT_PAGINATION_LIMIT,
            NO_TRANSACTION_LIST_URL,
            list:[],
            tableFields: [
                {key: 'id', label: this.$i18n.t('ID')},
                {key: 'reservation_code', label: this.$i18n.t('RESERVATION_CODE')},
                {key: 'source', label: this.$i18n.t('DISTRIBUTION'), tdClass: 'remove_padding text-nowrap'},
                {key: 'date', label: this.$i18n.t('PERIOD'), tdClass: 'text-nowrap'},
                {key: 'booking_date', label: this.$i18n.t('Booked at'), tdClass: 'remove_padding text-nowrap'},
                {key: 'status', label: this.$i18n.t('STATUS'), tdClass: 'text-nowrap'},
                {key: 'payment_status', label: this.$t('PAYMENT_STATUS'), thClass: 'text-nowrap', tdClass: 'text-center'},
                {key: 'charge_made_by', label: this.$t('CHARGE_MADE_BY'), thClass: 'text-nowrap', tdClass: 'text-center'},
                {key: 'payment_type', label: this.$t('PAYMENT_TYPE'), thClass: 'text-nowrap', tdClass: 'text-center'},
                {key: 'last_date_to_cancel', label: this.$i18n.t('Valid Cancel Upto'), tdClass: 'remove_padding text-nowrap'},
                {key: 'cancellation_time', label: this.$i18n.t('Cancelled at'), tdClass: 'remove_padding text-nowrap'},
                {key: 'total_gross', label: this.$i18n.t('GROSS_AMOUNT'), tdClass: 'remove_padding'},
                {key: 'total_net', label: this.$i18n.t('TOTAL'), tdClass: 'remove_padding'},
                {key: 'owner_amount', label: this.$i18n.t('OWNER_PART'), tdClass: 'remove_padding'},
                {key: 'purchase_amount', label: this.$i18n.t('PURCHASE_AMOUNT'), tdClass: 'remove_padding'},
                {key: 'refund_amount', label: this.$i18n.t('REFUND_AMOUNT'), tdClass: 'remove_padding'},
                {key: 'property_name', label: this.$i18n.t('PROPERTY_NAME'), tdClass: 'remove_padding'},
            ],
            additionalSortOptions: [
                {key: 'created_at', label: this.$t('CREATED_AT')},
            ],
        }
    },
    components: {
        AppTableV2
    },
    computed:{
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
    },
    methods: {
        getData(filter){
            let req = {
                ...this.filter,
                ...{
                    page: filter.currentPage,
                    perPage: filter.perPage,
                    company: this.company.id
                }
            }
            const provider = getNoTransactionReport(req)
            provider.then(response=>{
                this.list = response.data
            },
                (error) => {
                    toast({
                        'title': this.$t('Bad Request'),
                        'message': this.$t(error.response.data),
                        'type': 'error',
                        'timeout': 4000
                    })
                }
            )
            return provider
        },
        toggleBusy($event) {
            this.$emit('busyToggled', $event)
        },
    },
    watch: {
        filter: {
            handler() {
                EventBus.$emit('ge_get_report')
            },deep:true
        },
    }
}
</script>

<style scoped>

</style>
