<template>

    <b-card class="main-section-card">
        <div slot="header">
            <h2>{{ $t("No Transaction Report") }}</h2>
        </div>
        <no-transaction-filter @search="setFilter"  :loading="loading"></no-transaction-filter>
        <no-transaction-table :filter="filter" @busyToggled="toggleBusy" ></no-transaction-table>
    </b-card>
</template>

<script>
import NoTransactionFilter from "@/components/finance/no_transaction_report/NoTransactionFilter.vue";
import NoTransactionTable from "@/components/finance/no_transaction_report/NoTransactionTable.vue";
export default {
    name: "Index",
    components: {
        NoTransactionFilter, NoTransactionTable
    },
    data(){
        return{
            filter:null,
            loading:false
        }
    },
    methods:{
        setFilter(values) {
            this.filter = values
        },
        toggleBusy($event) {
            this.loading = $event
        }
    }
}
</script>

<style scoped>

</style>
